<template>
  <i>
    <svg width="100%" height="100%" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_19159_75627)">
        <path
          d="M6.02002 6.99023V13H4.94092L2.30127 9.18164H2.25146V13H1.00635V6.99023H2.10205L4.7251 10.8086H4.7832V6.99023H6.02002ZM7.03271 13V6.99023H11.0669V8.01953H8.27783V9.48047H10.8594V10.5098H8.27783V11.9707H11.0752V13H7.03271ZM13.3828 13L11.6646 6.99023H13.0259L14.0386 11.1904H14.0884L15.1924 6.99023H16.3628L17.4668 11.1987H17.5166L18.5293 6.99023H19.8906L18.1724 13H16.9521L15.8066 9.03223H15.7568L14.603 13H13.3828Z"
          fill="currentColor" />
      </g>
      <defs>
        <clipPath id="clip0_19159_75627">
          <rect width="100%" height="100%" fill="white" transform="matrix(1 0 0 -1 0 20)" />
        </clipPath>
      </defs>
    </svg>
  </i>
</template>

<style scoped lang="scss">
i {
  width: 20px;
  height: 20px;
  display: inline-block;
}
</style>
